<template>
  <component v-bind:is="currentTemplateComponent"></component>
</template>

<script>
import TDark from "../components/TDark.vue";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import TPrimary from "../components/TPrimary.vue";
import TElegant from "../components/TElegant.vue";
import TSimple from "../components/TSimple.vue";
import TModern from "../components/TModern.vue";
import TConcept from "../components/TConcept.vue";
import TCombined from "../components/TCombined.vue";
import TSublime from "../components/TSublime.vue";
import TAmbience from "../components/TAmbience.vue";
import TCascade from "../components/TCascade.vue";
import utilities from "../services/utilities";

export default {
  name: "preview",
  data: () => ({
    info: "",
  }),
  components: {
    TDark,
    TPrimary,
    TElegant,
    TSimple,
    TModern,
    TConcept,
    TCombined,
    TSublime,
    TAmbience,
    TCascade,
  },
  computed: {
    ...mapState(["cv"]),
    currentTemplateComponent() {
      console.log("PREVIEW SIZE: " + this.$vuetify.breakpoint.name);
      console.log("PREVIEW FONTSIZE: " + document.body.style.fontSize);
      return "t-" + this.cv.templateType.toLowerCase();
    },
  },
  props: {
    hash: {
      type: String,
    },
  },
  async mounted() {
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    // utilities.updateHeight();
    const response = await axios.get(`/api/cv/${this.$route.params.hash}`);
    this.info = response.data;
    console.log(response);
    this.updateCv(response.data);
  },
  methods: {
    ...mapActions(["updateCv"]),
  },
};
</script>

<style></style>
